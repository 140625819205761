<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <vx-card class="form-title" title="Add Vendor">
      <form>
        <div class="vx-col w-full">
          <!-- FIRST ROW -->
          <div class="row mb-6">
            <div class="col-6">
              <multiselect
                v-model="form.projects_value"
                track-by="project_id"
                label="project_name"
                :options="projects"
                v-validate="'required'"
                name="Project Name"
                placeholder="Select Project"
                :searchable="true"
                @select="categoryList"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.project_name
                }}</template>
                <!-- <span
                  class="text-danger text-xs error-msg"
                  v-show="errors.has('Project Name')"
                >{{ errors.first('Project Name') }}</span>-->
              </multiselect>
            </div>

            <div class="col-6">
              <!-- <h6>Item*</h6> -->
              <vs-input
                name="Item Name"
                v-validate="'required'"
                placeholder="Item Name"
                v-model="form.item_name"
                class="w-100"
              />
              <!-- <p class="error-msg">
                <span
                  class="text-danger text-xs error-msg"
                  v-show="errors.has('Item Name')"
                >{{ errors.first('Item Name') }}</span>
              </p>-->
            </div>
          </div>

          <!-- SECOND ROW -->
          <div class="mt-6 row mb-6">
            <div class="col-6">
              <multiselect
                v-model="form.category_value"
                track-by="category_id"
                label="category_name"
                :options="parentCategories"
                name="Category Name"
                placeholder="Select Category"
                :searchable="true"
                v-validate="'required'"
                @select="subCategoryList($event, index, 'category_id')"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.category_name
                }}</template>
                <!-- <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Category Name')"
                  >{{ errors.first('Category Name') }}</span>
                </p>-->
              </multiselect>
            </div>
            <div class="col-6">
              <multiselect
                v-model="form.sub_category_value"
                track-by="category_id"
                label="category_name"
                :options="childCategories"
                name="Subcategory Name"
                placeholder="Select Sub-Category"
                :searchable="true"
                @select="subCategoryList($event, index, 'sub_category_id')"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.category_name
                }}</template>
                <!-- <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Subcategory Name')"
                  >{{ errors.first('Subcategory Name') }}</span>
                </p>-->
              </multiselect>
            </div>
          </div>

          <!-- THIRD ROW -->
          <div class="mt-10 row">
            <div class="col-6">
              <multiselect
                v-model="form.vendors_value"
                track-by="vendor_id"
                label="vendor_name"
                :options="vendors"
                name="Vendor Name"
                placeholder="Select Vendor"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.vendor_name
                }}</template>
                <!-- <p class="error-msg">
                  <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Vendor Name')"
                  >{{ errors.first('Vendor Name') }}</span>
                </p>-->
              </multiselect>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-10">
                  <vs-input
                    v-validate="'required|numeric'"
                    name="Deal Amount"
                    v-model="form.deal_amount"
                    type="number"
                    placeholder="Deal Amount"
                    class="w-100"
                  />
                  <!-- <p class="error-msg">
                    <span
                      class="text-danger text-xs error-msg"
                      v-show="errors.has('Deal Amount')"
                    >{{ errors.first('Deal Amount') }}</span>
                  </p>-->
                </div>
                <div class="col-4 align-self-center">
                  <ul class="centerx list-inline">
                    <li class="list-inline-item">
                      <vs-radio v-model="form.amount_method" vs-value="1">
                        <feather-icon icon="PercentIcon" svgClasses="h-6 w-6" />
                      </vs-radio>
                    </li>
                    -->
                    <li class="list-inline-item">
                      <vs-radio v-model="form.amount_method" vs-value="2">
                        <feather-icon
                          icon="DollarSignIcon"
                          svgClasses="h-6 w-6"
                        />
                      </vs-radio>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vs-divider background="dark" class="mb-5" color="var(--vs-primary)"
          >INSTALLMENTS</vs-divider
        >

        <!-- INSTALLMENTS SECTION -->
        <div class="vx-col w-full">
          <div class="my-10 row">
            <!-- INSTALLMENT LOOP DATA START -->
            <div
              class="col-10 mb-5"
              v-for="(singleInstallment, index) in form.installment"
              :key="index"
            >
              <p class="h4 mb-2">
                Installment {{ index + 1 }}
                <span class="small"
                  >(Remaining Percent: {{ remaining_value }})</span
                >
              </p>
              <div class="row">
                <div class="col-7">
                  <vs-input
                    v-validate="'required|numeric'"
                    name="Installment Value"
                    placeholder="Installment Value"
                    type="number"
                    v-model="singleInstallment.installment_value"
                    class="w-100"
                  />
                  <!-- <p class="error-msg">
                    <span
                      class="text-danger text-xs error-msg"
                      v-show="errors.has('Installment Value')"
                    >{{ errors.first('Installment Value') }}</span>
                  </p>-->
                </div>
                <div class="col-3">
                  <datepicker
                    v-validate="'required'"
                    placeholder="Select Date"
                    name="Installment date"
                    v-model="singleInstallment.installment_date"
                    class="w-100"
                  ></datepicker>
                  <!-- <span
                    class="text-danger text-xs error-msg"
                    v-show="errors.has('Installment date')"
                  >{{ errors.first('Installment date') }}</span>-->
                  <!-- <p class="error-msg">
                    <span
                      class="text-danger text-xs error-msg"
                      v-show="errors.has('Installment Value')"
                    >{{ errors.first('Installment Value') }}</span>
                  </p>-->
                </div>
                <div class="col-2 align-self-center">
                  <vs-button
                    icon-pack="feather"
                    icon="icon-x"
                    @click="decrement(index)"
                  />
                </div>
              </div>
            </div>
            <!-- INSTALLMENT LOOP DATA END -->
            <div class="col-2 align-self-center">
              <vs-button
                type="filled"
                :disabled="plusStatus"
                @click.prevent="increment"
                class="mr-3"
                >+</vs-button
              >
            </div>
          </div>
        </div>

        <div class="text-center">
          <vs-button
            type="filled"
            :disabled="AddStatus"
            @click.prevent="addVendorContractForm"
            class="mr-3"
            >Add</vs-button
          >
          <vs-button type="filled" @click="finalSubmit" class="mr-3"
            >Submit</vs-button
          >
        </div>
      </form>
    </vx-card>

    <!-- NOTIFICATION PERMISSION POPUP -->
    <vs-popup
      class="holamundo"
      title="Lorem ipsum dolor sit amet"
      :active.sync="submitPopupActive"
    >
      <div class>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quasi hic
        omnis nulla error ea suscipit quaerat veniam quos. Fugiat alias eligendi
        sed quibusdam minus odio molestias illo voluptates tempora. Laudantium.
      </div>
    </vs-popup>
  </div>
</template>

<script>
const VxTour = () => import("@/components/VxTour.vue");
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { error } from "util";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ProjectService from "@/services/projectService.js";
import categoryService from "@/services/categoryService.js";
import Datepicker from "vuejs-datepicker";

export default {
  components: { flatPickr, Datepicker },
  data() {
    return {
      config: {
        maxDate: new Date(),
        minDate: null,
      },
      date: null,
      tempDealTotal: 0,
      projects: [],
      categoriesByProject: [],
      parentCategories: [],
      childCategories: [],
      vendors: [],
      submitPopupActive: false,
      amountIn: "",
      remaining_value: null,
      AddStatus: true,
      plusStatus: false,

      deal_active: 1,
      form: {
        amount_method: 1,
        projects_value: [],
        item_name: "",
        category_value: [],
        sub_category_value: [],
        deal_amount: null,
        installment: [
          {
            installment_date: "",
            installment_value: "",
            installment_status: 1,
          },
        ],
      },
      vendors_value: [],
      // installment: [
      //   {
      //     installment_date: "",
      //     installment_value: "",
      //     installment_status: 1
      //   }
      // ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },

    form: {
      handler: function (newVal, oldVal) {
        this.tempDealTotal = 0;
        let len = Object.keys(newVal).length;
        // if(newVal == )
        // let array = ["name", "name1"];
        // if (
        //   array.length ===
        //   array.filter(
        //     x =>
        //       newVal[x] &&
        //       newVal[x] !== undefined &&
        //       String(newVal[x]).trim().length > 0
        //   ).length
        // ) {
        // } else {
        // }
        let final_len = Object.keys(newVal).filter(
          (x, index) =>
            newVal[x] &&
            newVal[x] !== undefined &&
            String(newVal[x]).trim().length > 0
        ).length;
        let flag = false;

        Object.keys(newVal).map((x) => {
          console.log("x :", x);
          if (x == "installment") {
            let arr = newVal[x].filter((y) => {
              return y.installment_value.length > 0;
            });
            let dates = newVal[x].filter((z) => {
              return z.installment_date > 0;
            });
            console.log("arr :", dates);
            // let dates = newVal[x].filter(z => {
            //   return z.installment_date > 0;
            // });

            if (arr.length > 0) {
              arr.map((y) => {
                this.tempDealTotal += parseFloat(y.installment_value);

                // console.log("dates len :", dates.length);
                // console.log("arr len :", arr.length);
                if (
                  // this.form.amount_method == 2 &&
                  // this.tempDealTotal > 0 &&
                  // this.tempDealTotal == this.form.deal_amount
                  this.form.amount_method == 1 &&
                  this.tempDealTotal == 100 &&
                  dates.length == arr.length
                  // this.tempDealTotal <= this.form.deal_amount
                ) {
                  flag = true;
                } else if (
                  this.form.amount_method == 2 &&
                  this.tempDealTotal == this.form.deal_amount &&
                  dates.length == arr.length
                ) {
                  flag = true;
                } else {
                  flag = false;
                }
              });
            } else {
              flag = false;
            }
          }
        });

        if (len === final_len && flag) {
          this.AddStatus = false;
          this.plusStatus = true;
          // } else if (len === final_len && !flag) {
          //   this.plusStatus = false;
          //   this.AddStatus = true;
        } else {
          this.AddStatus = true;
          if (this.tempDealTotal >= 100 && this.form.amount_method == 1) {
            alert("inPercent");
            this.plusStatus = true;
          } else if (
            this.tempDealTotal >= this.form.deal_amount &&
            this.form.amount_method == 2
          ) {
            alert("inAbsolute");
            this.plusStatus = true;
          } else {
            this.plusStatus = false;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    finalSubmit: function () {
      this.submitPopupActive = true;
    },
    increment: function () {
      this.form.installment.push({
        installment_date: "",
        installment_value: "",
        installment_status: 1,
      });
    },
    decrement(index) {
      // if (index.length > 0) {
      if (index > 0) {
        this.form.installment.splice(index, 1);
      } else {
        this.form.installment = [
          {
            installment_date: "",
            installment_value: "",
            installment_status: 1,
          },
        ];
      }
    },
    getProjectsList: function () {
      ProjectService.getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.projects = data.data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    categoryList: function (selectedOption) {
      this.categoriesByProject = [];
      this.parentCategories = [];
      this.childCategories = [];
      this.form.category_value = [];
      this.form.sub_category_value = [];
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
    },
    getAllCategoryListByProject: function (id) {
      categoryService
        .getAllcategoriesByProject(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoriesByProject = data.data;
            this.parentCategories = data.data.filter((x) => x.parent_id == 0);
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    subCategoryList: function (selectedOption, index, type) {
      this.childCategories = [];
      this.sub_category_value = [];
      if (type == "category_id") {
        this.parent_id = 0;
        this.category_id = selectedOption.category_id;
        this.category_name = selectedOption.category_name;
      } else {
        this.parent_id = selectedOption.parent_id;
        this.category_id = selectedOption.category_id;
        this.sub_category_name = selectedOption.category_name;
      }
      this.category_id = selectedOption.category_id;
      this.parent_id = selectedOption.category_id;
      this.getAllSubCategoryListByParentCategory(
        this.parent_id,
        this.project_id
      );
    },
    getAllSubCategoryListByParentCategory: function (parent, project) {
      categoryService
        .getSubCategoriesByParentCategory({ parent, project })
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoriesByParent = data.data;
            this.childCategories = this.categoriesByParent.filter(
              (y) => y.parent_id === parent
            );
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    addVendorContractForm: function () {
      //       this.$vs.loading();
      //        let payload = {
      //   ...this.form,
      //   company_description: this.company_description,
      //   company_logo: this.company_logo
      // };
    },
    // submitForm() {
    //   this.$validator.validateAll().then(result => {
    //     if (result) {
    //       this.submitStatus = false;
    //       this.$vs.loading();
    //       let payload = {
    //         ...this.form,
    //         company_description: this.company_description,
    //         company_logo: this.company_logo
    //       };
    //       CompanyService.addCompany(payload)
    //         .then(response => {
    //           this.$vs.loading.close();
    //           this.submitStatus = false;
    //           const { data } = response;
    //           if (data.Status == true || data.Status == "true") {
    //             this.$vs.notify({
    //               title: "Updated!",
    //               text: data.Message,
    //               iconPack: "feather",
    //               icon: "check_box",
    //               color: "success"
    //             });
    //             setTimeout(() => {
    //               eventBus.$emit("refreshTablecomp", data);
    //             }, 1);
    //             this.clearForm();
    //           } else {
    //             console.log("data :", data);
    //             this.$vs.loading.close();
    //             this.$vs.notify({
    //               title: "Error!",
    //               text: data.Message,
    //               iconPack: "feather",
    //               icon: "check_box",
    //               color: "warning"
    //             });
    //           }
    //         })
    //         .catch(error => {
    //           this.$vs.loading.close();
    //           this.submitStatus = true;
    //           console.log("error :", error);
    //         });
    //       // if form have no errors
    //       // alert("form submitted!");
    //     } else {
    //       // form have errors
    //     }
    //   });
    // },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.company_description = "";
      this.$validator.reset();
      this.value = [];
      this.clearImage();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {},
  beforeMount() {
    this.getProjectsList();
  },
};
</script>
